@import '../../../assets/styles/variables';

.carousel {
  position: relative;

    &__image {
        height: 25rem;
        position: relative;
        overflow: hidden;
        img {
            width: 100%;
            height: 90%;

            transition: transform 400ms ease-in-out;
        }

        :hover > img {
            transform: scale(1.3);
        }

        @media (max-width: $medium-width) {
            height: 20rem;
        }
    }

    &__buttons {
        button {
            width: 3.5rem;
            height: 3.5rem;
            background-color: white;
            cursor: pointer;
            color: #000;
            border: none;
            position: absolute;
            top: 45%;
            right: 5rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background-color 0.3s;

            &:hover {
                background-color: rgb(199, 199, 199);
            }
        }   

        .back {
            left: 5rem;
        }

        @media (max-width: 768px) {
            button {
                width: 3rem;
                height: 3rem;
                right: 4rem;
            }

            .back {
                left: 4rem;
            }
        }

        @media (max-width: 480px) {
            button {
                width: 2.5rem;
                height: 2.5rem;
                right: 3rem;
            }

            .back {
                left: 3rem;
            }
        }
    }
}