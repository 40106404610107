@import '../../../assets/styles/variables';

.active {
    color: $navbar-links-color-hover;
}

.navbar {
    height: $navbar-height;
    display: flex;
    align-items: center;

    &__content {
        width: 60%;
        margin: 0 auto;

        @media (max-width: $medium-width) {
            width: 70%;
        }

        @media (max-width: $xmedium-width) {
            width: 80%;
        }

        @media (max-width: $small-width) {
            width: 100%;
            margin: 0 20px;
        }

        &__links {
            display: flex;
            justify-content: space-between;

            &-link {
                p {
                    margin: 0;
                }

                text-decoration: none;
                color: $navbar-links-color;
                position: relative;
                transition: color 0.5s ease;

                &::before {
                    content: "";
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: $navbar-links-color-hover;
                    transition: width 0.5s ease;
                }

                &:hover {  
                    color: $navbar-links-color-hover;
                    &::before {
                        width: 100%;
                    }
                }

                &.active {
                    color: $navbar-links-color-hover;
                    &::before {
                        width: 100%;
                    }
                }
            }
        }
    }
    
    &__hamburger {
        display: none;
        cursor: pointer;
        font-size: 30px;
        position: fixed;
        top: 20px;
        left: 20px;
        z-index: 1000;
        color: $navbar-hamburger-icon-color;

        @media (max-width: $small-width) {
            display: block;
        }
    }

    @media (max-width: $small-width) {
        height: $navbar-xsmall-height;
        
        &__content__links {
            display: none;
        }
    }
}

.offcanvas-wrapper {
    color: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 85%;
    height: 100%;
    background-color: $navbar-hamburger-background-color;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    z-index: 1001; 
    overflow-y: auto;
    color: $navbar-hamburger-social-color;

    &__header {
        margin-top: 20px;
        display: flex;
        align-items: center;

        &-close {
            width: 70px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            position: fixed;

            &:hover {
                cursor: pointer;
                color: $navbar-hamburger-links-color-hover;
            }
        }

        &-logo {
            margin: 0 auto;
            height: 100px;
            text-align: center;
            img {
                max-height: 100%;
            }
        }
    }

    &__body {
        margin-top: 100px;
        text-align: left;
        margin: 30px;

        &-links {
            border-bottom: 1px solid $navbar-hamburger-separate-line-color;
            &__link {
                color: $navbar-hamburger-links-color;
                text-decoration: none;
                font-size: 16px;
                transition: color 0.3s, background-color 0.3s; 

                p {
                    margin-top: 10px;

                    &:hover {
                        color: $navbar-hamburger-links-color-hover;
                    }
                }
            }
        }

        &-social {
            margin-top: 30px;

            &__network {
                margin-bottom: 10px;
                display: flex;
                align-items: center;

                p {
                    margin: 0 0 0 5px;
                }

                &:hover {
                    color: $navbar-hamburger-social-color-hover;
                    cursor: pointer;
                }
            }
        }
    }
}
  
  .offcanvas-wrapper.show {
    transform: translateX(0);
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    z-index: 999; /* esto asegura que el overlay esté justo debajo del Offcanvas */
  }
  
  .overlay.show {
    visibility: visible;
    opacity: 1;
  }
  
  /* Estilo para bloquear el scroll cuando el offcanvas esté abierto */
  .no-scroll {
    overflow: hidden;
  }
