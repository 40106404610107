@import '../../../assets/styles/variables';

.home-service-individual-therapy {
    $color: #ffb5db9f;
    background-color: $color;
    @media (max-width: $xsmall-width) {
        background-color: none;
        background: linear-gradient(to bottom, white, $color, $color, white);
    }
}

.home-service-couple-therapy {
    $color: #b5c9ff9d;
    background-color: $color;
    @media (max-width: $xsmall-width) {
        background-color: none;
        background: linear-gradient(to bottom, white, $color, $color, white);
    }
}

.home-service-group-workshops {
    $color: #edb5ff8d;
    background-color: $color;
    @media (max-width: $xsmall-width) {
        background-color: none;
        background: linear-gradient(to bottom, white, $color, $color, white);
    }
}

.home {
    &__header {
        background-color: white !important;
        padding: 50px 0;

        &__content {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            opacity: 0; 
            transform: translateY(-50px);
            transition: opacity 0.5s, transform 0.5s; 
            animation: slideUp 1s forwards;

            img {
                max-width: 400px;
            }

            h1 {
                text-transform: uppercase;
                font-size: 45px;
            }

            h2, h3 {
                font-size: 25px;
                font-weight: 400;
            }

            &__buttons {
                margin-top: 50px;
                width: 350px;
                display: flex;
                justify-content: space-between;

                &-appointment, &-about-me {
                    padding: 10px 15px;
                    border-radius: 3px;
                
                    &:hover {
                        cursor: pointer;
                    }
                }

                &-appointment {
                    background-color: $main-color-4-less-opacity;

                    &:hover {
                        background-color: $main-color-4;
                    }
                }

                &-about-me {
                    background-color: $main-color-5-less-opacity;

                    &:hover {
                        background-color: $main-color-5;
                    }
                }
            }

            @media (max-width: $xsmall-width) {
                img {
                    max-width: 90%;
                }

                &__buttons {
                    width: 100%;
                    justify-content: space-around;
                }
            }
        }

        @media (max-width: $xsmall-width) {
            padding-top: 0;
        }
    }

    &__services {
        display: flex;
        justify-content: space-between;
        text-align: center;

        &-service {
            width: 100%;
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;

            &__content {
                display: flex;
                flex-direction: column;
                align-items: center;

                h2 {
                    font-size: 30px;
                    font-weight: 900;
                }

                &-description {
                    max-width: 70%;
                }
            }
        }

        @media (max-width: $xmedium-width) {
            &-service {
                height: 400px;
            }
        }

        @media (max-width: $xsmall-width) {
            flex-direction: column;

            &-service {
                height: 250px;

                &__content {
                    h2 {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &__about {
        display: flex;

        h2 {
            font-size: 25px;
            font-weight: 900;
        }

        &-approach {
            width: 50%;
            background-color: #ffd9b57e;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h2 {
                font-size: 30px;
            }

            p {
                text-align: center;
                max-width: 600px;
                font-size: 20px;
                padding: 0 12px;
                margin: 5px;
            }

            @media (max-width: $xsmall-width) {
                background: linear-gradient(white, #ffd9b57e);
            }
        }

        &-picture {
            width: 25%;
            display: flex;
            justify-content: center;
            img {
                max-width: 100%;
            }

            @media (max-width: $medium-width) {
                width: 100%;
                background: linear-gradient(#ffd9b57e, $main-color-6);
            }
        }

        &-text {
            width: 25%;
            background-color: $main-color-6;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 400px;

            h2 {
                font-size: 30px;
            }

            p {
                text-align: center;
                max-width: 350px;
                font-size: 20px;
                margin: 5px 10px;
            }
        }

        @media (max-width: $medium-width) {
            flex-direction: column;

            &-approach, &-picture, &-text {
                width: 100%;
            }

            &-approach {
                padding: 20px 0;

                p {
                    margin: 0 10px;
                }
            }

            &-picture {
                img {
                    max-height: 400px;
                }
            }

            &-text {
                padding: 20px 0;
                min-height: auto;

                p {
                    margin: 5px 10px;
                    max-width: 600px;
                    text-align: center;
                }
            }
        }
    }

    &__philosophy {
        padding: 50px 0;
        text-align: center;
        background-color: white;

        h2 {
            font-size: 30px;
            font-weight: 900;
        }

        p {
            max-width: 800px;
            margin: 20px auto;
            text-align: justify;
        }

        &-description {
            font-size: 20px;
            padding: 0 20px;
        }

        &-steps {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 80%;
            margin: 50px auto;

            &__step {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 20%;

                &-form__1, &-form__2, &-form__3, &-form__4, &-form__5 {
                    width: 90px;
                    height: 90px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 35px;
                }

                &-form__1 {
                    background-color: #ffb5db9f;
                }

                &-form__2 {
                    background-color: #ffd9b57e;
                }

                &-form__3 {
                    background-color: $main-color-3;
                }

                &-form__4 {
                    background-color: $main-color-5;
                }

                &-form__5 {
                    background-color: $main-color-4;
                }

                p {
                    color: rgb(125, 125, 125);
                }
            }
        }

        @media (max-width: $medium-width) {
            &-steps {
                max-width: 100%;
            }
        }

        @media (max-width: $xmedium-width) {
            &-steps {
                &__step {
                    font-size: 15px;
                    &-form__1, &-form__2, &-form__3, &-form__4, &-form__5 {
                        width: 70px;
                        height: 70px;
                    }
                }
            }

            padding-bottom: 0px;
        }

        @media (max-width: $small-width) {
            p {
                margin: 20px 20px;
            }

            &-steps {
                flex-direction: column;

                &__step {
                    width: 100%;
                }
            }
        }
    }

    &-carousel {
        h2 {
            font-size: 30px;
            font-weight: 900;
            text-align: center;
            margin: 0 10px;

            @media (max-width: $small-width) {
                font-size: 25px;
            }
        }

        width: 100%;
        margin: 0 auto;
        position: relative;

        &__slide {
            box-sizing: border-box;
            display: block;
            text-align: center;
            height: 600px !important;
        }
    }

    &__why-therapy {
        padding: 50px 0;
        text-align: center;
        background-color: $main-color-6;

        h2 {
            font-size: 30px;
            font-weight: 900;
            margin-right: 5px;
            margin-left: 5px;
        }

        h3 {
            max-width: 800px;
            margin: 10px auto;
            padding: 0 5px;
            font-size: 20px;
        }

        &__cards {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            margin-top: 50px;

            &-card {
                border-radius: 10px;
                border: 2px solid $main-color-1;
                margin: 10px 0;
                width: 300px;

                &__title {
                    padding-bottom: 10px;
                    border-bottom: 2px solid $main-color-1;
                    font-weight: bold;
                    font-style: italic;
                    font-size: 18px;
                }

                &__content {
                    margin: 10px;
                    font-size: 14px;
                }
            }
        }
    }

    &__sentence {
        padding: 100px 20px;
        background: linear-gradient($main-color-6, $main-color-2, white);;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            font-size: 100px;
        }

        p {
            margin: 0 20px;
            font-size: 28px;
            text-align: center;
            font-family: 'Roboto Mono', monospace;
        }

        @media (max-width: $small-width) {
            p {
                font-size: 20px;
            }
        }
    }
}