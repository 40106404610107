@import '../../../assets/styles/variables';

.footer {
    background-color: $footer-background-color;
    color: $footer-color;

    &__whatsapp {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 50px;
        margin: 0;
        position: fixed;
        bottom: 30px;
        right: 20px;
        z-index: 999;
        color: $footer-whatsapp-button-color;

        &:hover {
            font-size: 70px;
        }
    }

    &__info {
        display: flex;
        justify-content: space-between;
        max-width: 1280px;
        margin: 0 auto;
        padding: 50px 0;

        border-bottom: 1px solid gray;

        &-text, &-contact, &-social {
            padding: 20px;
            flex: 1;

            h3 {
                letter-spacing: 4px;
                font-size: 24px;
                font-family: 'Marcellus', serif;
                text-transform: uppercase;
            }

            p {
                font-size: 15px;
                letter-spacing: 2px;
            }
        }

        &-text {
            h3 {
                letter-spacing: 4px;
                font-size: 24px;
                font-family: 'Marcellus', serif;
            }
        }

        &-contact {
            white-space: pre-line;
            overflow-wrap: break-word;
        }

        &-social {
            &__network {
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                p {
                    margin: 0 0 0 5px;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        @media (max-width: $small-width) {
            flex-direction: column;
        }
    }

    &__copyright {
        text-align: center;
        margin: 0 10px;
        padding: 20px 0;
        font-weight: 400;
        line-height: 1.66;
        font-size: 14px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;

        @media (max-width: $small-width) {
            flex-direction: column;
        }
    }
}