/** SIZES **/
$general-backgrond-color: white;
$general-color: black;
$medium-width: 1200px;
$xmedium-width: 1000px;
$small-width: 780px;
$xsmall-width: 570px;
$main-color-1: rgb(204 198 198);
$main-color-2: rgb(251 220 214);
$main-color-3: rgb(229 199 181);
$main-color-4: #b5dcff;
$main-color-4-less-opacity: #b5dcff69;
$main-color-5: rgb(232 239 241);
$main-color-5-less-opacity: rgba(232, 239, 241, 0.634);
$main-color-6: rgb(247 246 246);
$main-color-7: rgb(219, 240, 161);
$main-color-8: rgb(234, 178, 234);

/** SERVICES **/
$price-table-border-color: rgb(190, 166, 166);

/** NAVBAR **/
$navbar-background-color: white;
$navbar-height: 100px;
$navbar-xsmall-height: 70px;
$navbar-links-color: rgb(118, 112, 120);
$navbar-links-color-hover: black;

$navbar-hamburger-icon-color: black;
$navbar-hamburger-background-color: white;
$navbar-hamburger-links-color: rgb(118, 112, 120);
$navbar-hamburger-links-color-hover: black; 
$navbar-hamburger-separate-line-color: $main-color-4;
$navbar-hamburger-social-color: rgb(118, 112, 120);
$navbar-hamburger-social-color-hover: black;

/** FOOTER **/
$footer-background-color: white;
$footer-color: black;
$footer-whatsapp-button-color: #25D366;

/** KEYFRAMES **/
@keyframes slideUp {
    to {
        opacity: 1; 
        transform: translateY(0); 
    }
}