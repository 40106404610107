@import '../../../assets/styles/variables';

.group-workshops {
    background: linear-gradient(to bottom, #edb5ff8d, white);

    &__header {
        transform: translateY(-50px);
        transition: opacity 0.5s, transform 0.5s; 
        animation: slideUp 1s forwards;

        h1 {
            font-size: 38px;
            text-align: center;
            padding: 20px 0;
            margin-top: 0;
        }
    }

    &__content {
        max-width: 50%;
        margin: 0 auto;

        p {
            margin-bottom: 40px;
            text-align: justify;
        }
    }

    &__prices {
        width: 15%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1px solid $price-table-border-color;
        border-radius: 5px;

        @media (max-width: $xmedium-width) {
            width: 20%;
        }

        @media (max-width: $small-width) {
            width: 30%;
        }

        @media (max-width: $xsmall-width) {
            width: 30%;

            h4 {
                height: 60px;
            }
        }

        h4 {
            border-bottom: 1px solid $price-table-border-color;
            padding: 10px 0;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-bonus {
            width: 100%;
            h4 {
                background-color: #e4a3fa56;
            }
        }
    }

    &__appointment {
        padding: 10px;
        text-align: center;
        height: 50px;
        max-width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $main-color-4-less-opacity;
        border-radius: 5px;
        margin: 40px auto 0 auto;

        &:hover {
            cursor: pointer;
            background-color: $main-color-4;
        }
    }

    @media (max-width: $medium-width) {
        &__content {
            max-width: 90%;
        }
    }
}