@import './assets/styles/fonts';
@import '../src/assets/styles/variables';
// react-slick styles
@import "slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background-color: $general-backgrond-color;
  color: $general-color;
  margin: 0;
  padding: 0;
  height: 100%;
}

/* REACT-SLICK STYLES OVERWRITE */


.slick-list, .slick-slider, .slick-track{
  padding: 0;
}

.slick-dots {
  bottom: 50px;
}

ul.slick-dots {
  li {
      div {
          background: rgb(153, 149, 149);
      }
  }

  li.slick-active {
      div {
          background: white;
      }
  }
}

.center .slick-center{
  z-index: 10000;
  transition: all 400ms ease-in-out;
  pointer-events: all;
  opacity: 1;
}
