@import '../assets/styles/variables';

.error404 {
    margin: 50px 0;
    text-align: center;

    &__appointment {
        padding: 10px;
        text-align: center;
        height: 50px;
        max-width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $main-color-4-less-opacity;
        border-radius: 5px;
        margin: 40px auto 40px auto;

        &:hover {
            cursor: pointer;
            background-color: $main-color-4;
        }
    }
}