.public-layout {
    &__individual-therapy-color {
        background-color: #ffb5db9f;
    }

    &__couple-therapy-color {
        background-color: #b5c9ff9d;
    }

    &__group-workshops-color {
        background-color: #edb5ff8d;
    }

    &__white-color {
        background-color: white;
    }
}