@import '../../../assets/styles/variables';

.appointments {
    h1, h4 {
        text-align: center;
        max-width: 50%;
        margin: 20px auto;
    }

    h4 {
        margin-top: 40px;
    }

    &__calendly {
        margin-top: 60px;
    
        @media (max-width: $small-width) {
            max-width: 90%;
            margin: 0 auto;
        }
    }
}