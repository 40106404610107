@import '../../../assets/styles/variables';

.about {
    max-width: 50%;
    margin: 0 auto;

    h1, h2 {
        text-align: center;
    }

    &__header {
        &-summary {
            text-align: center;
            margin: 40px 0;
        }

        &-experience {
            div {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                img {
                    width: 30px;
                }

                p {
                    margin-left: 10px;
                }
            }
        }

        &-help-with {
            max-width: 100%;
            margin-top: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__text, &__image {
                margin: 0 5px;
            }

            &__text {
                width: 40%;

                div {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    img {
                        width: 30px;
                    }

                    p {
                        margin: 1px;
                        margin-left: 10px;
                    }
                }
            }

            &__image {
                width: 50%;
            }

            ul {
                font-size: 18px;
            }

            img {
                max-width: 100%;
            }
        }
    }

    @media (max-width: $medium-width) {
        max-width: 70%;
    }

    @media (max-width: $xmedium-width) {
        max-width: 80%;
    }

    @media (max-width: $small-width) {
        max-width: 80%;

        &__header {
            &-help-with {
                margin-top: 20px;
                margin-bottom: -80px;
                flex-direction: column;

                div {
                    margin-bottom: 20px;
                }

                &__text, &__image {
                 width: 100%;
                }
            }
        }
    }
}